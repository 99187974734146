import React from "react"
import Row from "./bootstrap/row"
import SponsorBox from "./sponsor-box"
import { OutboundLink } from "gatsby-plugin-google-analytics"
import { Link } from "gatsby"

import Microsoft from "../images/logos/microsoft.png"
import Poly from "../images/logos/poly.png"
import RingCentral from "../images/logos/ringcentral.png"
import Zoom from "../images/logos/zoom.png"

import atkinsrealis from "../images/logos/atkinsrealis.svg"
import concerto from "../images/logos/concerto.svg"
import controlup from "../images/logos/controlup.svg"
import genesys from "../images/logos/genesys.svg"
import JLL from "../images/logos/jll.svg"
import TTEC from "../images/logos/ttec.svg"
import Wazoku from "../images/logos/wazoku.png"
import IBM from "../images/logos/IBM_Partner_Plus_platinum_partner_mark_pos_platinum_RGB.svg"
import Socitm from "../images/logos/socitm.png"
import CIPD from "../images/logos/cipd.png"
import AWS from "../images/logos/aws.svg"
import Salesforce from "../images/logos/salesforce.svg"

const HeadlineSponsors = ({gridColor}) => {

  return (
    <div style={{
      maxWidth: `700px`,
      margin: `auto`
    }}>
    <div style={{position: `relative`}}>
      <div style={{
        position: `absolute`,
        width: `100%`,
        height: `100%`,
        background: `repeating-linear-gradient(-45deg, ${gridColor}, ${gridColor} 4px, transparent 4px, transparent 10px)`,
        bottom: `-1rem`,
        left: `-1rem`,
        zIndex: `-1`,
      }} />
      <div className="row justify-content-center">
        {/* <div className={"col-6 col-md-2"}>
          <SponsorBox bgColor="rgb(210, 52, 100)" fontColor="#fff" border="2px rgb(210, 52, 100) solid">Supported<br />By</SponsorBox>
        </div> */}
        <div className={"col col-sm-4 col-md-4 col-lg-3 mb-4"}>
          <SponsorBox border="2px #fff solid"><OutboundLink href="https://www.atkinsrealis.com/" target="_blank"><img src={atkinsrealis} className="img-fluid" /></OutboundLink></SponsorBox>
        </div>
        <div className={"col-6 col-sm-4 col-md-4 col-lg-3 mb-4"}>
          <SponsorBox border="2px #fff solid"><OutboundLink href="https://aws.amazon.com/" target="_blank"><img src={AWS} className="img-fluid p-3" /></OutboundLink></SponsorBox>
        </div>
        {/* <div className={"col-6 col-sm-4 col-md-4 col-lg-3 mb-4"}>
          <SponsorBox border="2px #fff solid"><OutboundLink href="https://www.cipd.org" target="_blank"><img src={CIPD} className="img-fluid p-2" /></OutboundLink></SponsorBox>
        </div> */}
        <div className={"col-6 col-sm-4 col-md-4 col-lg-3 mb-4"}>
          <SponsorBox border="2px #fff solid"><OutboundLink href="https://www.concerto.co.uk/" target="_blank"><img src={concerto} className="img-fluid p-1" /></OutboundLink></SponsorBox>
        </div>
        <div className={"col-6 col-sm-4 col-md-4 col-lg-3 mb-4"}>
          <SponsorBox border="2px #fff solid"><OutboundLink href="https://www.controlup.com/" target="_blank"><img src={controlup} className="img-fluid p-1" /></OutboundLink></SponsorBox>
        </div>
        <div className={"col-6 col-sm-4 col-md-4 col-lg-3 mb-4"}>
          <SponsorBox border="2px #fff solid"><OutboundLink href="https://www.genesys.com/en-gb" target="_blank"><img src={genesys} className="img-fluid" /></OutboundLink></SponsorBox>
        </div>
        {/* <div className={"col-6 col-md-2"}>
          <SponsorBox bgColor="transparent" border="2px #fff solid" fontColor="#fff"><Link to="/partners/" style={{ color: `#fff` }}>And Many More...</Link></SponsorBox>
        </div> */}
      {/* </Row>
      <Row> */}
        {/* <div className={"col-6 col-md-2"}>
          <SponsorBox bgColor="rgb(210, 52, 100)" fontColor="#fff" border="2px rgb(210, 52, 100) solid">Supported<br />By</SponsorBox>
        </div> */}
        <div className={"col-6 col-sm-4 col-md-4 col-lg-3 mb-4"}>
          <SponsorBox border="2px #fff solid"><OutboundLink href="https://www.ibm.com/uk-en" target="_blank"><img src={IBM} className="img-fluid" /></OutboundLink></SponsorBox>
        </div>
        <div className={"col-6 col-sm-4 col-md-4 col-lg-3 mb-4"}>
          <SponsorBox border="2px #fff solid"><OutboundLink href="https://www.jll.co.uk" target="_blank"><img src={JLL} className="img-fluid p-2" /></OutboundLink></SponsorBox>
        </div>
        <div className={"col-6 col-sm-4 col-md-4 col-lg-3 mb-4"}>
          <SponsorBox border="2px #fff solid"><OutboundLink href="https://www.salesforce.com/uk/" target="_blank"><img src={Salesforce} className="img-fluid" /></OutboundLink></SponsorBox>
        </div>
        <div className={"col-6 col-sm-4 col-md-4 col-lg-3 mb-4"}>
          <SponsorBox border="2px #fff solid"><OutboundLink href="https://socitm.net" target="_blank"><img src={Socitm} className="img-fluid" /></OutboundLink></SponsorBox>
        </div>
        <div className={"col-6 col-sm-4 col-md-4 col-lg-3 mb-4"}>
          <SponsorBox border="2px #fff solid"><OutboundLink href="https://ttecdigital.com/" target="_blank"><img src={TTEC} className="img-fluid" /></OutboundLink></SponsorBox>
        </div>
        <div className={"col-6 col-sm-4 col-md-4 col-lg-3 mb-4"}>
          <SponsorBox border="2px #fff solid"><OutboundLink href="https://www.wazoku.com/" target="_blank"><img src={Wazoku} className="img-fluid" /></OutboundLink></SponsorBox>
        </div>
        {/* <div className={"col-6 col-sm-4 col-md-4 col-lg-3 mb-4"}>
          <SponsorBox bgColor="transparent" border="2px #fff solid" fontColor="#fff"><Link to="/partners/" style={{ color: `#fff` }}>And Many More...</Link></SponsorBox>
        </div> */}
      </div>
    </div>
    </div>
  )
}

HeadlineSponsors.defaultProps = {
  gridColor: `#d23464`
  // gridColor: `rgba(251,146,1,1)`
}

export default HeadlineSponsors
